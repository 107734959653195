import connectionMixin from "@/mixins/connectionParams.js";
import axios from 'axios'
import runtimes from '@/assets/runtimes.json'

export default {
  name: "FunctionCreateForm",
  mixins: [connectionMixin],
  components: {},
  data() {
    return {
      code: "",
      functionName: "",
      error: "",
      errorEnable: "",
    };
  },

  mounted() {
    this.runtimeObj = runtimes[this.$route.params.language.toLowerCase()];
    this.language = this.runtimeObj.language;
    this.code = this.runtimeObj.defaultCode.replace(
      "##DEFAULTHANDLER##",
      this.runtimeObj.defaultHandler
    );
    this.functionName = this.$route.params.id;

    if (this.language != "nodejs") {
      this.languageIcon = "mdi-language-" + this.language;
    } else {
      this.languageIcon = "mdi-" + this.language;
    }

    this.languageColor = this.runtimeObj.color;
    this.languageExt = this.runtimeObj.extension;
  },

  methods: {
    formSend() {
      let data = {};
      data.serviceName = this.functionName;
      data.language = this.$route.params.language;
      data.version = "1_0_0";
      data.files = [];
      this.availableFiles.forEach((file) => {
        data.files.push({ name: file.name, code: file.code });
      });
      axios
        .post(this.apihost + "/api/v1/services/", data, this.opts)
        .then((response) => {
          if (Math.floor(response.status / 100) === 2) {
            this.$router.push({ name: "Functions" });
          }
        })
        .catch((err) => {
          this.errorHandler(err);
        });
    },

    errorHandler(err) {
      this.errorEnable = true;
      if (err.response.status == 400) {
        this.error = this.$t("message.error.function.create_error", {
          functionName: " " + this.functionName,
        });
      }
      if (err.response.status == 403) {
        this.error = this.$t("message.error.function.unauthorized_error");
      }
    },

    cancelCreate() {
      this.$router.push({ name: "Functions" });
    },
  },
};