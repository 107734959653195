<template>
    <div>
        <v-btn @click="onPickFile"
            fab           
            small
            class="mx-2 my-1"
        >
            <v-icon>mdi-folder-open-outline</v-icon>
        </v-btn>
        <input type="file" @change="loadTextFromFile"
                ref="fileInput"
                class="d-none"
                :accept="langs"
        >
    </div>
</template>

<script>
export default {
  props: {langs: Array},
  methods: {
    loadTextFromFile(ev) {
      const file = ev.target.files[0];
      const reader = new FileReader();

      reader.onload = e => this.$emit("load", e.target.result);
      reader.readAsText(file);
    },
    onPickFile () {
        this.$refs.fileInput.click()
    }
     
  }
};
</script>