<template>
    <v-row>
        <v-col sm="auto" class="explorer-col-style flex-grow-1">
            <v-toolbar color="primary" dark flat height="25px" class="d-flex justify-center"><span>{{
                $t("message.labels.explorer")
            }}</span></v-toolbar>
            <template>
                <v-treeview class="overflow-y-auto flex-grow-1" :open="initiallyOpen" :items="items" activatable
                    item-key="name" open-on-click return-object @update:active="getContent" @update:open="formatFilePath">
                    <template v-slot:prepend="{ item, open }">
                        <v-icon v-if="!item.file">
                            {{ open? 'mdi-folder-open': 'mdi-folder' }}
                        </v-icon>
                        <v-icon v-else>
                            {{ files[item.file] }}
                        </v-icon>
                    </template>
                </v-treeview>
                <v-dialog v-model="dialog" max-width="600px" v-if="!this.$route.params.readOnly">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn color="primary" dark v-bind="attrs" v-on="on">
                            {{ $t("message.btns.add") }}
                        </v-btn>
                    </template>
                    <v-card>
                        <v-card-title class="text-h5">
                            {{ $t("message.labels.enter-file-name") }}
                        </v-card-title>
                
                        <v-card-text>
                            <v-text-field :label="$t('message.labels.file-name')" v-model="fileName" required></v-text-field>
                        </v-card-text>
                
                        <v-card-actions>
                            <v-spacer></v-spacer>
                
                            <v-btn color="secondary" text @click="dialog = false">
                                {{ $t("message.btns.cancel") }}
                            </v-btn>
                
                            <v-btn color="secondary" @click="addFile">
                                {{ $t("message.btns.add") }}
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
                <v-btn class="ms-1" color="secondary" text dark v-if="currentFile.length > 0 && !this.$route.params.readOnly" @click="deleteFile">
                    {{ $t("message.btns.delete") }}
                </v-btn>
            </template>
        </v-col>
        <v-col sm="auto" class="file-content-col-style flex-grow-1">
            <v-toolbar color="primary" dark flat height="25px" width="895px"
                class="d-flex justify-start file-content-col-toolbar-style">{{ this.filePath }}</v-toolbar>
            <v-sheet ref="codeEditor" :height="h" width="800">
                <monaco-editor @input="changeCode" v-model="code" theme="vs" :language="language" :options="options"
                    automaticLayout="true" width="910" height="250">
                </monaco-editor>
            </v-sheet>
        </v-col>
        <v-snackbar light top elevation="1" v-model="snackbar">
            {{ text }}
            <template v-slot:action="{ attrs }">
                <v-btn color="pink" text v-bind="attrs" @click="snackbar = false">
                    {{ $t('message.btns.no') }}
                </v-btn>
                <v-btn color="pink" text v-bind="attrs" @click="redirectToFileDeletion">
                    {{ $t('message.btns.yes') }}
                </v-btn>
            </template>
        </v-snackbar>
    </v-row> 
</template>

<script>
import axios from "axios";
import connectionMixin from "@/mixins/connectionParams.js";
import MonacoEditor from "@lucianodltec/monaco-editor-vue";
export default {
    name: "CodeExplorer",
    props: ["availableFiles"],
    mixins: [connectionMixin],
    components: {
        MonacoEditor,
    },
    data: () => ({
        code: "",
        language: "",
        h: "450",
        options: {
            //Monaco Editor Options
            readOnly: false
        },
        initFiles: [],
        initiallyOpen: [],
        files: {
            html: 'mdi-language-html5',
            js: 'mdi-nodejs',
            json: 'mdi-code-json',
            md: 'mdi-language-markdown',
            pdf: 'mdi-file-pdf',
            png: 'mdi-file-image',
            txt: 'mdi-file-document-outline',
            xls: 'mdi-file-excel',
            rb: 'mdi-language-ruby',
            lock: 'mdi-package-variant-closed',
            py: 'mdi-language-python',
            go: 'mdi-language-go',
            mod: 'mdi-package-variant-closed',
            java: 'mdi-language-java',
            xml: 'mdi-xml',
            php: 'mdi-language-php'
        },
        tree: [],
        items: [],
        filePath: "/",
        functionFolder: {},
        currentFile: "",
        dialog: false,
        fileName:"",
        snackbar: false,
        text: ""
    }),
    mounted() {
        if(this.$route.params.readOnly) {
            this.options.readOnly = true; 
            this.getFunctionFiles(this.$route.params.id, this.$route.params.language);
        } else {
            this.runtime_logo = this.$route.params.language;
            this.functionName = this.$route.params.id;
            this.getFunctionCode(this.$route.params.id, this.$route.params.initFiles);
        }
        
    },
    methods: {
        getFunctionDetails(id, language) {
            axios
                .post(this.apihost + "/api/v1/files/" + language + "?functionName=" + id, this.opts)
                .then((response) => {                                                
                    this.runtime_logo = language.toLowerCase();
                    this.functionName = id;
                    this.getFunctionCode(response.data);
                })
                .catch((err) => {
                    console.error("failed to get function details", err);
                });
        },
        getFunctionFiles(id, language) {
            let path = {
                language: language,
                folderName: id,
                fileName: id + "-1_0_0.tar.gz",
            };
            axios
                .post(this.apihost + "/api/v1/files/inspect", path, this.opts)
                .then((response) => {
                    let data = response.data;
                    this.runtime_logo = language.toLowerCase();
                    this.functionName = id;
                    this.items.push({
                        name: id,
                        children: []
                    });
                    data.files.forEach(file => {
                        if (file.name != "Dockerfile") {
                            this.items[0].children.push({ name: file.name, file: file.name.split(".")[1] });
                        }
                        this.availableFiles.push({name: file.name, code: file.code});
                    });        
                })
                .catch((err) => {
                    console.error("failed to get function details", err);
                });
        },
        getFunctionCode(serviceName, initFiles) {
            this.items.push({
                name: serviceName,
                children: [],
            });
            this.initiallyOpen = [serviceName];
            initFiles.forEach((file) => {
                if (file.name != "Dockerfile" && file.name.split(/^[^.]+\./)[1] != "tar.gz") {
                    this.items[0].children.push({ name: file.name, file: file.name.split(".")[1] });
                    this.availableFiles.push({ name: file.name, code: file.code });
                }
                if (file.name == "Dockerfile") {
                    this.availableFiles.push({ name: file.name, code: file.code });
                }       
            });
        },
        formatFilePath(opened) {
            if (opened.length > 0) {
                this.filePath = "";
                for (const i of opened) {
                    this.filePath += "/" + i.name;   
                }
            } else {
                this.filePath = "/";
            }
        },
        changeCode() {
            if(this.currentFile != "") {
                let fileToChange = this.availableFiles.filter(file => file.name == this.currentFile)[0];
                fileToChange.code = this.code;
            }          
        },
        getContent(node) {
            if(node[0]) {
                this.currentFile = node[0].name;
                this.code = this.availableFiles.filter(file => file.name == node[0].name)[0].code;
            } else {
                this.currentFile = "";
            }   
        },
        addFile() {
            this.dialog = false;
            this.items[0].children.push({ name: this.fileName, file: this.fileName.split(".")[1] });
            this.availableFiles.push({ name: this.fileName, code: "" });
        },
        deleteFile() {
            this.text = this.$t("message.functioncreation.snackbar.functionfiledeletion", { file: this.currentFile });
            this.snackbar = true;
        },
        redirectToFileDeletion() {
            this.snackbar = false;
            this.items[0].children = this.items[0].children.filter(file => file.name !== this.currentFile);
            this.availableFiles = this.availableFiles.filter(file => file.name !== this.currentFile);
            this.currentFile = "";
        }
    }

}
</script>

<style scoped>
.explorer-col-style {
    padding-right: 0;
    padding-left: 0;
}

.v-treeview {
    max-height: 300px;
}

.file-content-col-style {
    padding-left: 0;
}

.file-content-col-toolbar-style {
    padding-left: 45px;
}
</style>