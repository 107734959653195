<template>
  <v-dialog v-model="dialog" scrollable max-width="350px" persistent>
    <template v-slot:activator="{ on, attrs }">
      <v-btn v-bind="attrs" v-on="on" class="mx-2 my-1" fab small>
        <v-icon>mdi-cog</v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-card-title>{{ $t("message.labels.settings") }}</v-card-title>
      <v-divider></v-divider>
      <v-card-text style="height: 300px;">
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-text-field
                ref="inputTimeout"
                :label="$t('message.labels.timeout')"
                :hint="$t('message.labels.max-execution-time')"
                persistent-hint
                required
                :value="timeout"
                :rules="rulesTimeout"
                @change="updateTimeout($event)"
              >
                <template v-slot:append>
                  <v-select
                    ref="inputUnit"
                    class="unitTime"
                    dense
                    v-model="unitDefault"
                    :items="unitTime"
                    single-line
                    style="width: 60px"
                    hide-details
                    @change="changeUnitBounds"
                  ></v-select>
                </template>
              </v-text-field>
            </v-col>
            <v-col cols="12">
              <v-text-field
                ref="inputHandler"
                :label="$t('message.labels.handler')"
                :hint="$t('message.labels.handler-description')"
                persistent-hint
                required
                :value="handler"
                :rules="rulesHandler"
                @change="updateHandler($event)"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text @click="dialog = false">
          Close
        </v-btn>
        <v-btn text @click="updateSettings">
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    handler: String,
    timeout: Number,
  },
  data() {
    return {
      dialog: false,
      unitDefault: "ms",
      unitTime: ["ms", "sec"],
      rulesTimeout: [
        (value) =>
          !!value || this.$t("message.error.function.timeout-required"),
        (value) =>
          (this.unitDefault == "sec" && value >= 1 && value <= 60) ||
          (this.unitDefault == "ms" && value >= 1 && value <= 60000) ||
          this.$t("message.error.function.timeout-range"),
        (value) => {
          const pattern = /^([0-9]+)$/;
          return (
            pattern.test(value) ||
            this.$t("message.error.function.timeout-invalid")
          );
        },
      ],
      rulesHandler: [
        (value) =>
          !!value || this.$t("message.error.function.handler-required"),
        (value) => {
          const pattern = /^([a-z0-9]+)$/;
          return (
            pattern.test(value) ||
            this.$t("message.error.function.handler-invalid")
          );
        },
      ],
      newHandler: "init",
      newTimeout: 1000,
    };
  },
  methods: {
    changeUnitBounds(value) {
      this.unitDefault = value;
    },
    updateHandler(value) {
      this.newHandler = value;
    },
    updateTimeout(value) {
      var unit = this.$refs.inputUnit.value;
      if (unit == "sec") {
        value = value * 1000;
      }
      this.newTimeout = value;
    },
    updateSettings() {
      this.$emit("update:handler", this.newHandler);
      this.$emit("update:timeout", parseInt(this.newTimeout));
      this.dialog = false;
    },
  },
};
</script>

<style></style>
