<template>
  <v-container>
    <core-toolbar-name
      :page-name="
        this.$t('message.pages.title.create_function_form', {
          lang: ' ' + this.$route.params.language.toLowerCase(),
        })
      "
    ></core-toolbar-name>

    <v-form>
      <v-container class="form-container">
        <v-row dense>
          <v-col sm="12" md="12" class="d-flex justify-start">
            <v-card elevation="0" width="100%" class="py-2">
              <v-text-field
                outlined
                :label="$t('message.labels.function-name')"
                class="text-h5"
                v-model="functionName"
                :rules="rules"
                disabled
              >
                <v-icon
                  slot="prepend"
                  :color="languageColor"
                  x-large
                  class="mt-n2 mx-n1 mb-0"
                >
                  {{ languageIcon }}
                </v-icon>
              </v-text-field>
            </v-card>
          </v-col>
        </v-row>

        <code-explorer :availableFiles="availableFiles"></code-explorer>

        <v-row class="">
          <v-col cols="12" class="d-flex flex-row-reverse">
            <v-btn color="secondary" @click="formSend()">
              {{ $t("message.btns.create") }}
            </v-btn>
            <v-btn class="mr-2" color="primary" text @click="cancelCreate()">
              {{ $t("message.btns.cancel") }}
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-form>

    <v-card-text>
      <v-snackbar v-model="errorEnable" top color="error" right>
        "{{ error }}"
      </v-snackbar>
    </v-card-text>
  </v-container>
</template>

<script>
import CoreToolbarName from "@/components/core/ToolbarName.vue";
import ModalSettings from "@/components/ModalSettings";
import TextReader from "@/components/TextReader";
import functionFillFormMixin from "@/mixins/views/pages/FunctionsCreateForm.js";
import screenfull from "screenfull";
// The plugin @lucianodltec/monaco-editor-vue works fine only with monaco-editor-webpack-plugin v.2.1.0
import CodeExplorer from '../CodeExplorer.vue';

export default {
  name: "FunctionsCreateForm",
  data() {
    return {
      languageIcon: "",
      languageColor: "",
      rules: [
        (value) => !!value || this.$t("message.error.function.name-required"),
        (value) =>
          value.length <= 20 || this.$t("message.error.function.name_lenght"),
        (value) => {
          const pattern = /^([a-z0-9]+)$/;
          return (
            pattern.test(value) ||
            this.$t("message.error.function.name-invalid")
          );
        },
      ],
      availableFiles: [],
    };
  },
  components: {
    CoreToolbarName,
    ModalSettings,
    TextReader,
    CodeExplorer,
  },
  mixins: [functionFillFormMixin],
  methods: {
    goBack() {
      this.$router.push("/");
    },
    fullscreen() {
      if (screenfull.isEnabled) {
        screenfull.request(this.$refs.target);
        this.h = this.$refs.codeEditor.height = window.innerHeight - 120;
        this.w = this.$refs.codeEditor.width = window.innerWidth - 56;
      }
    },
  },
};
</script>

<style scoped>
.form-container {
  max-height: 630px;
}
.explorer-col-style {
  padding-right: 0;
  padding-left: 0;
}
.v-treeview {
  max-height: 300px;
}
.file-content-col-style {
  padding-left: 0;
}
.file-content-col-toolbar-style {
  padding-left: 45px;
}
</style>
